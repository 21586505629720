<template>
  <b-carousel
    v-show="showCarousel"
    id="sample-image-carousel"
    ref="sampleImageCarousel"
    v-images-loaded="carouselImagesLoaded"
    :interval="2000"
    fade
    indicators
  >
    <b-carousel-slide
      v-for="(sampleImg, index) in sampleImages"
      :key="index"
      :img-src="sampleImg"
      :img-alt="sampleImgAltText(index)"
    />
  </b-carousel>
</template>

<script>
import imagesLoaded from 'vue-images-loaded'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

export default {
  name: 'SampleImagesCarousel',
  directives: {
    imagesLoaded
  },
  props: {
    sampleImages: { type: Array, default: function () { return [] }, required: true }
  },
  data () {
    return {
      showCarousel: true
    }
  },
  created () {
    document.addEventListener('keyup', (ev) => {
      if (ev.key === 'ArrowLeft') {
        this.$refs.sampleImageCarousel.prev()
      } else if (ev.key === 'ArrowRight') {
        this.$refs.sampleImageCarousel.next()
      }
    })
  },
  methods: {
    carouselImagesLoaded () {
      this.showCarousel = true
    },
    sampleImgAltText (imgIndex) {
      return `Sample Image #${imgIndex + 1}` // +1 because zero-based index
    }
  }
}
</script>

<style lang="scss">
.carousel-indicators li:focus {
  outline: 3px solid #3dbbdb !important;
  opacity: 1;
}
</style>
